.navbar-container {
  height: 120px;
  padding: 0 !important;

  .navbar-toolbar {
    height: 120px;
    margin-top: 0px;
    justify-content: space-between;
    padding: 0 !important;
  }

  .navbar-logo {
    height: 120px;
  }

  .navbar-links {
    text-align: right;
    margin-right: 20px;

    @media (max-width: 600px) {
      & {
        margin-top: -16px;
        margin-right: 0;
      }
    }
  }

  .navbar-link {
    margin-right: 40px;
    transition: color 0.2s;

    @media (max-width: 600px) {
      & {
        margin-right: 20px;
      }
    }
  }

  .navbar-link-special {
    border-left: 2px solid #ababab;
    margin: 0px;
    padding-left: 40px;
  }

  .navbar-icon {
    height: 20px;
    margin-left: 8px;
    transform: translateY(4px);
  }
}
