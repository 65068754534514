.footer {
  height: 120px;

  @media (max-width: 600px) {
    & {
      height: 100%;
      padding-bottom: 12px;
    }
  }

  .footer-container {
    display: flex;
    align-items: center;

    @media (max-width: 600px) {
      & {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .footer-logo {
    height: 120px;
    margin-right: 30px;
  }

  .footer-column {
    display: flex;
    flex-direction: column;
  }

  .footer-rights {
    margin-top: 16px;
    color: #999;
  }
}
