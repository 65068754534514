.modal {
  align-items: flex-start;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  left: 50%;
  min-height: 500px;
  min-width: 50%;
  max-width: 90%;
  outline: none;
  padding: 24px 32px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 600px) {
  .modal {
    min-width: 90%;
    padding: 12px 16px;
  }
}

.showToken-loading {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: 500px;
  width: 100%;
}
