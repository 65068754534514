.refreshCounter {
  display: flex;
  gap: 10px;
}

.headRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  width: 100%;
}

.loadMore {
  cursor: pointer;
  margin-top: 8px;
  text-align: right;
  text-decoration: underline;
  transition: color .2s;

  &:hover {
    color: #225AFF;
  }
}
