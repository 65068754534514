body {
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-size: 0.875rem;
  font-family: 'Mulish';
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

a {
  text-decoration: none;
  color: inherit;
}

hr {
  margin: 0;
}

.appContainer {
  min-height: calc(100vh - 114px);
  padding-top: 30px;

  @media (max-width: 600px) {
    & {
      padding-top: 12px;
    }
  }
}

.cardanoName {
  color: #2a36ff;
}
