.getListed {
  color: #fff;
  background-color: #2a36ff;
  overflow: hidden;
  padding: 32px 120px;
  position: relative;

  @media (max-width: 1200px) {
    & {
      padding: 32px 30px;
    }
  }

  @media (max-width: 600px) {
    & {
      padding: 32px 12px;
    }
  }

  .gl-cardano-logo {
    bottom: 6px;
    height: 400px;
    left: calc(100% - 300px);
    overflow: hidden;
    position: absolute;
  }

  .gl-container {
    padding: 0 120px;

    @media (max-width: 1200px) {
      & {
        padding: 0;
      }
    }
  }

  .gl-title {
    font-size: 2.4em;
    font-weight: bold;

    @media (max-width: 600px) {
      & {
        font-size: 1.6em;
      }
    }
  }

  .gl-subtitle {
    font-size: 1.4em;
    font-weight: normal;
    margin-top: -8px;
    margin-bottom: 24px;

    @media (max-width: 600px) {
      & {
        font-size: 1.2em;
        margin-top: 0;
      }
    }
  }

  .gl-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:last-of-type {
      margin-top: 8px;
    }

    @media (max-width: 600px) {
      & {
        flex-direction: column;
        justify-content: space-between;
      }

      &:last-of-type {
        flex-direction: row;
        justify-content: flex-start;
        padding: 0 22px;
      }
    }
  }

  .gl-form-column {
    display: flex;
    flex-direction: column;
    width: 525px;
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }

    @media (max-width: 600px) {
      margin-right: 0;
      width: 90%;
    }
  }

  .gl-icon {
    margin-left: 4px;
  }

  .gl-upload {
    height: 24px;
    width: 24px;
  }

  .gl-send {
    height: 14px;
    width: 14px;
  }

  .gl-actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 140px;
    margin-right: 20px;

    .gl-submitButton {
      width: 100px;
    }
  }

  input[type='file'] {
    display: none;
  }

  .gl-input {
    height: 100%;
    position: relative;
    width: 100%;

    .error-message {
      color: #d32e2e;
      top: 68px;
      position: absolute;
    }

    &.gl-message {
      .error-message {
        top: 228px;
      }
    }

    &.gl-input-image {
      background-color: #fff;
      border-radius: 8px;
      box-shadow: inset 0 0 3px #c4c4c4;
      height: 100px;
      width: 100px;

      img {
        border-radius: 8px;
      }

      .error-message {
        top: 100px;
      }
    }
  }
}
