  .image {
    display: block;
    margin: 32px auto;
    width: 60%;

    @media (max-width: 800px) {
      & {
        width: 100%;
      }
    }
  }

  .content {
    @media (max-width: 800px) {
      margin: 0px 16px;
    }
  }

  .articleImage {
    width: 250px;
  }
