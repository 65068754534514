.info {
  border-radius: 12px;
  box-shadow: 0 0 18px 10px #dee6ff;
  margin: 60px 120px;
  padding: 36px;
  padding-right: 212px;

  @media (max-width: 1200px) {
    & {
      margin: 60px;
      padding-right: 36px;
    }
  }

  @media (max-width: 600px) {
    & {
      margin: 60px 30px;
      padding-right: 36px;
    }
  }
}
